@import "variables";
.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.px20 {
  padding-right: 20px;
  padding-left: 20px;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.bg-white {
  background: $white;
}

@mixin safe-padding-top($defaultPadding) {
  padding-top: max(env(safe-area-inset-top, 0), #{$defaultPadding});
}

@mixin safe-padding-bottom($defaultPadding) {
  padding-bottom: max(env(safe-area-inset-bottom, 0), #{$defaultPadding});
}

.padding-top-safe-0 {
  padding-top: 0px;
}

.padding-top-safe-20 {
  padding-top: 20px;
}

.padding-bottom-safe-0 {
  padding-bottom: 0px;
}

@supports (padding-top: max(0px, 0px)) {
  //check if max is supported
  .padding-top-safe-0 {
    padding-top: env(safe-area-inset-top, 0);
  }
  .padding-top-safe-20 {
    @include safe-padding-top(20px);
  }
  .padding-bottom-safe-0 {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
}

.error-permission-txt {
  color: red;
  font-size: 20px;
}
