@import "./variables";

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: map-get($vg-gray, 500);
  height: 3px;
}

.mat-tab-group[mat-stretch-tabs] > .mat-tab-header .mat-tab-label {
  border-bottom: 3px solid #8b8b8b;
}

.mat-tab-group {
  font-family: map-get($vg-font-family, lato);
}

.mat-tab-label {
  border-bottom: 2px solid #d8d8d8;
  font-family: map-get($vg-font-family, lato);
}

.tab-bar-green-container {
  .mat-tab-label .mat-tab-label-content {
    color: white;
  }

  .mat-tab-label-active .mat-tab-label-content {
    color: rgb(99, 181, 112);
  }
  .mat-ink-bar {
    background-color: white;
  }
  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: rgb(99, 181, 112);
  }
}
