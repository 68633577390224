.white-transparent-toggle-group {
  border-color: white;
  border-radius: 5px !important;

  .mat-button-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
    color: white !important;
    font-family: "Lato";
    font-size: 12px;
    line-height: 16px;
    height: 25px;
    border-radius: 3px !important;
  }

  .mat-button-toggle-checked {
    background-color: white !important;
    color: rgb(52, 64, 81) !important;
  }
}
