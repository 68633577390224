/*
 * Device size definitions
 * (Sizing pulled from material.io spec)
 */
$handset-small: 360px;
$handset-portrait: 480px;
$handset-landscape: 600px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$web: 1280px;
$web-large: 1440px;
$web-xlarge: 1600px;

@mixin respond-to($media) {
  @if $media == handset-small {
    @media only screen and (max-width: $handset-small - 1px) {
      @content;
    }
  } @else if $media == handset-and-smaller {
    @media only screen and (max-width: $handset-landscape - 1px) {
      @content;
    }
  } @else if $media == handset-portrait {
    @media only screen and (min-width: $handset-small) and (max-width: $handset-portrait - 1px) {
      @content;
    }
  } @else if $media == handset-landscape {
    @media only screen and (min-width: $handset-portrait) and (max-width: $handset-landscape - 1px) {
      @content;
    }
  } @else if $media == handset-plus {
    @media only screen and (min-width: $handset-small) {
      @content;
    }
  } @else if $media == tablet-portrait {
    @media only screen and (min-width: $handset-landscape) and (max-width: $tablet-portrait - 1px) {
      @content;
    }
  } @else if $media == tablet-landscape {
    @media only screen and (min-width: $tablet-portrait) and (max-width: $tablet-landscape - 1px) {
      @content;
    }
  } @else if $media == tablet-plus {
    @media only screen and (min-width: $tablet-portrait) {
      @content;
    }
  } @else if $media == web-plus {
    @media only screen and (min-width: $tablet-landscape) {
      @content;
    }
  } @else if $media == desktop-plus {
    @media only screen and (min-width: $web) {
      @content;
    }
  }
}
