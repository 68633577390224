.select-white-outline {
  .mat-select-arrow {
    color: white !important;
  }

  .mat-select-value-text {
    color: white !important;
  }
}

.time-zone-select {
  .mat-form-field-underline {
    display: none !important;
  }

  mat-select {
    font-family: "lato" !important;
    font-size: 18px !important;
    color: #344051 !important;
  }
}
