/* You can add global styles to this file, and also import other style files */

@import "./scss/material";
@import "./scss/utils";
@import "./scss/variables";
@import "./scss/theme";
@import "./scss/form";
@import "./scss/tab";
@import "./scss/card";
@import "./scss/carousel";
@import "./scss/button";
@import "./scss/checkbox";
@import "./scss/toast";
@import "./scss/select";
@import "./scss/dialog";
@import "./scss/toolbar";
@import "./scss/option";
@import "./scss/toggle";
@import "./scss/spinner";
@import "./scss/slide-toggle";
@import "./styles/toolkit";

// @import "~bootstrap/scss/bootstrap.scss";
html,
body {
  height: 100%;
  mat-form-field {
    width: 95%;
  }
  .spinner {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    background: url(/link/to/your/image);
    background-size: 100%;
  }
}
body {
  margin: 0;
  font-family: Lato, sans-serif;
}
/* paginator custom style */

.list-users-paginator {
  .mat-paginator-outer-container {
    position: relative;
  }
  .mat-paginator-container {
    flex: 3;
    justify-content: flex-start !important;
    padding: 0 !important;
  }
  .mat-paginator-page-size-label {
    margin-left: 0 !important;
  }
  .mat-paginator-range-actions {
    position: absolute;
    width: 50%;
    margin: 0 25%;
    justify-content: center;
  }
  .paginator-range-label {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    text-align: right;
    padding-top: 20px;
  }
}
.paginator-range-label {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-align: right;
  padding-top: 20px;
}

/* remove outline from button on focus */
button:focus {
  outline: none;
}
