@import "./token";
@import "./variables";

.main-container {
  .mat-card {
    border: $vg-panel-border;
    border-radius: $vg-panel-radius;
  }
}

.mat-card {
  font-family: map-get($vg-font-family, lato);
}
