// Element resize
@mixin resize($width, $height: $width) {
  width: $width;
  height: $height;
}

// Flex
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center-start() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-center-end() {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start() {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-start-center() {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-start-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-end() {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-end-spaced() {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

// Border Radius
@mixin border-top-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-bottom-radius($radius: 0) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius: 0) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-right-radius($radius: 0) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

// Text
@mixin ellipses {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin add-sibling-comma($margin-right: 0, $margin-left: 0) {
  &:not(:last-of-type) {
    &:after {
      display: inline-block;
      content: ", ";

      margin: 0 $margin-right 0 $margin-left;
    }
  }
}

/** Material Components */
/**
 * Resizes material buttons
 */
@mixin resize-mat-button($width, $height: $width) {
  @include resize($width, $height);
  line-height: $height;
}

/**
 * Resizes material icons
 */
@mixin resize-mat-icon($width, $height: $width) {
  @include resize-mat-button($width, $height);

  font-size: $height;
}

/**
 * Resizes material icons inside buttons
 */
@mixin resize-mat-icon-button($size) {
  @include resize-mat-button($size);

  mat-icon {
    $size: $size - 12px;

    @include resize-mat-icon($size);
  }
}
