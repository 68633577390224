// global - color tokens

$green-color: #63b570;
$light-green-color: #b2d13b;
$black-color: #344051;
$yellow-color: #e6d25d;

$red-color: #ff2b2b;
$white-color: #ffffff;

$dark-purple-color: #6a3d9a;
$pink-color: #fb9a99;
$light-purple-color: #cab2d6;
$blue-color: #1f78b4;
$light-blue-color: #a6cee3;
$light-green-color: #b2df8a;
$purle-color: #806fb8;
$orange-color: #ff7f00;
$light-orange-color: #fdbf6f;

$dark-blue-1-color: #0f4650;
$dark-blue-2-color: #135662;
$dark-blue-3-color: #093941;
$dark-blue-4-color: #024743;

$dark-blue-5-color: #135662;
$dark-blue-6-color: #334051;
$dark-blue-7-color: #285757;

$gray-10-color: #ebecf0;
$gray-15-color: #d8d8d8;
$gray-20-color: #d2d1d2;
$gray-25-color: #bdbdbd;
$gray-50-color: #838183;
$gray-75-color: #434343;

// size

$flex-xs-max: 599px;
$flex-sm-min: 600px;
$flex-sm-max: 959px;
$flex-md-min: 960px;
$flex-md-max: 1279px;
$flex-lg-min: 1280px;
$flex-lg-max: 1919px;
$flex-xl-min: 1920px;
$flex-xl-max: 5000px;

@mixin for-media($size) {
  @if $size == xs {
    @media screen and (max-width: $flex-xs-max) {
      @content;
    }
  } @else if $size == sm {
    @media screen and (max-width: $flex-sm-min) and (max-width: $flex-sm-max) {
      @content;
    }
  } @else if $size == md {
    @media screen and (max-width: $flex-md-min) and (max-width: $flex-md-max) {
      @content;
    }
  } @else if $size == lg {
    @media screen and (max-width: $flex-lg-min) and (max-width: $flex-lg-max) {
      @content;
    }
  } @else if $size == xl {
    @media screen and (max-width: $flex-xl-min) and (max-width: $flex-xl-max) {
      @content;
    }
  } @else if $size == lt-sm {
    @media screen and (max-width: $flex-xs-max) {
      @content;
    }
  } @else if $size == ls-md {
    @media screen and (max-width: $flex-sm-max) {
      @content;
    }
  } @else if $size == ls-lg {
    @media screen and (max-width: $flex-md-max) {
      @content;
    }
  } @else if $size == ls-xl {
    @media screen and (max-width: $flex-lg-max) {
      @content;
    }
  } @else if $size == gt-xs {
    @media screen and (min-width: $flex-sm-min) {
      @content;
    }
  } @else if $size == gt-sm {
    @media screen and (min-width: $flex-md-min) {
      @content;
    }
  } @else if $size == gt-md {
    @media screen and (min-width: $flex-lg-min) {
      @content;
    }
  } @else if $size == gt-lg {
    @media screen and (min-width: $flex-xl-min) {
      @content;
    }
  }
}

$border-5-radius: 5px;
// font sizes: 20px 16px

$lato-font: "Lato", "serif";

// VoltaGrid Main Alias

$vg-background-color: $black-color;
$vg-primary-font: $lato-font;
$vg-font-color-on-dark: $white-color;
$vg-font-color-on-light: $black-color;

$vg-dark-font-color: $black-color;
$vg-light-font-color: $white-color;

$vg-menu-background-color: $gray-10-color;
$vg-menu-hr-color: $gray-50-color;
$vg-menu-select-color: $gray-15-color;

$vg-panel-radius: $border-5-radius;

$vg-panel-title-color: $dark-blue-6-color;
$vg-panel-title-size: 20px;
$vg-panel-title-weight: 500;
$vg-panel-border: 1px solid $gray-15-color;

$vg-panel-title-sub-size: 16px;
$vg-panel-title-sub-color: $gray-25-color;
$vg-panel-title-sub-weight: 400;

$vg-sub-panel-title-color: $white-color;
$vg-sub-panel-title-size: 14px;
$vg-sub-panel-title-line-height: 18px;
$vg-sub-panel-title-weight: 700;

$vg-sub-panel-background-1: $dark-blue-1-color;
$vg-sub-panel-background-2: $dark-blue-3-color;
$vg-sub-panel-background-3: $dark-blue-2-color;
$vg-sub-panel-background-4: $dark-blue-4-color;
$vg-sub-panel-background-5: $gray-75-color;
$vg-sub-panel-background-7: $dark-blue-7-color;

$vg-trailer-indicator-size: 24px;
$vg-trailer-unknown: $gray-50-color;
$vg-trailer-active: $green-color;
$vg-trailer-inactive: $yellow-color;
$vg-trailer-offline: $red-color;

$vg-trailer-battery-radius: $vg-panel-radius;
$vg-trailer-battery-border: 2px solid $white-color;
$vg-trailer-battery-indicator-radius: $border-5-radius;
$vg-trailer-battery-height: 60px;
$vg-trailer-battery-max-width: 959px;
$vg-trailer-battery-min-width: 400px;
$vg-trailer-battery-empty: $gray-50-color;
$vg-trailer-battery-good: $green-color;
$vg-trailer-battery-warn: $yellow-color;
$vg-trailer-battery-low: $red-color;

/////// MOVE MOVE MOVE ///////

.gauge-container {
  min-width: 230px;
}

.panel-title-sub {
  font-family: $vg-primary-font;
  color: $vg-sub-panel-title-color;
  size: $vg-sub-panel-title-size;
  line-height: $vg-sub-panel-title-line-height;
  font-weight: $vg-sub-panel-title-weight;

  margin: 13px 0 11px 0;
}

.voltagrid-panel {
  padding-top: 20px;

  .voltagrid-panel-container {
    padding: 0;

    ::ng-deep .mat-card-header-text {
      margin: 0 5px;
    }

    mat-card-title {
      font-family: $vg-primary-font;
      margin: 8px 0 10px 0;

      .trailer-indicator {
        width: 24px;
        height: 24px;
      }

      .trailer-name {
        color: $vg-panel-title-color;
        font-size: $vg-panel-title-size;
        font-weight: $vg-panel-title-weight;
      }

      .trailer-type {
        color: $vg-panel-title-sub-color;
        font-size: $vg-panel-title-sub-size;
        font-weight: $vg-panel-title-sub-weight;
      }

      .trailer-name,
      .trailer-type {
        line-height: 20px;
      }
    }

    vg-stacked-number-graph {
      margin-bottom: 10px;
    }
  }
}
