.carousel-container {
  background-color: #0f4650;
}

.carousel-arrow {
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  background-color: #d8d8d8 !important;
}

.carousel-arrow-prev {
  left: 0 !important;
}

.carousel-arrow-next {
  right: 0 !important;
}
