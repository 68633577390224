@import "./variables";

mat-form-field {
  font-family: map-get($vg-font-family, lato) !important;
}

.mat-form-field-appearance-outline {
  color: #344051;
}

.mat-form-field-label {
  color: #344051;
}

app-root {
  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin-top: 5px;
  }
}

.mat-form-field-no-top-padding {
  .mat-form-field-infix {
    padding-top: 0 !important;
  }
}

.mat-form-field-white-background {
  .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
    border-color: #344051;
  }
}

.vg-form-indent-level-1 {
  padding: 0 10px;
}

.vg-form-indent-level-2 {
  padding: 0 20px;
}

.mat-form-field-dark-green-background {
  color: white;

  .mat-form-field-outline {
    background-color: #093941;
    border-radius: 5px;
    border-color: white;
    color: white;
  }

  .mat-form-field-label {
    color: white;
  }

  input {
    color: white;
  }

  input::placeholder {
    color: gray;
  }
}
