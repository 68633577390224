$primary-color: #3f51b5;
$white: #ffffff;
$main-background-color: #252f3e;
$main-background-color-mobile: #093941;
$background-gradient-mobile: radial-gradient(
    circle at 0 0,
    rgba(228, 240, 193, 1) 0%,
    rgba(186, 227, 216, 1) 100%
  )
  0 0;
$gray: #404041;
// @import 'theme';
// $primary-color: $mat-blue;

$max-width-phone: 480px;
$max-width-tablet: 1024px;
$min-width-tablet: calc(#{$max-width-phone} + 1px);

$trailer-online-color: #63b570;
$trailer-offline-color: #ff2b2b;
$trailer-disabled-color: #e6d25d;
$trailer-na-color: #9b9b9b;

$vg-graph-background: (
  graph0: #093941,
  graph1: #024743,
  graph2: #0f4650,
  graph3: #135662,
  gauge: #135662,
  fuel: #0f4650,
);

$vg-green: (
  50: #ecf6ee,
  100: #d0e9d4,
  200: #b1dab8,
  300: #92cb9b,
  400: #7ac085,
  500: #63b570,
  600: #5bae68,
  700: #51a55d,
  800: #479d53,
  900: #358d41,
  A100: #daffde,
  A200: #a7ffb1,
  A400: #74ff85,
  A700: #5aff6e,
  contrast: (
    50: #797a7b,
    100: #7e8081,
    200: #616365,
    300: #474849,
    400: #000000,
    500: #ffffff,
    600: #ddddeb,
    700: #e1e8ee,
    800: #f5f5f5,
    900: #ffffff,
    A100: #838486,
    A200: rgba(0, 0, 0, 0.26),
    A400: #000000,
    A700: #000000,
  ),
);

$vg-gray: (
  50: #e7e8ea,
  100: #c2c6cb,
  200: #9aa0a8,
  300: #717985,
  400: #525d6b,
  500: #344051,
  600: #2f3a4a,
  700: #273240,
  800: #212a37,
  900: #151c27,
  A100: #6aa6ff,
  A200: #3787ff,
  A400: #0469ff,
  A700: #005dea,
  contrast: (
    50: #797a7b,
    100: #7e8081,
    200: #616365,
    300: #474849,
    400: #000000,
    500: #ffffff,
    600: #ddddeb,
    700: #e1e8ee,
    800: #f5f5f5,
    900: #ffffff,
    A100: #838486,
    A200: rgba(0, 0, 0, 0.26),
    A400: #000000,
    A700: #000000,
  ),
);

$vg-font-family: (
  lato: "lato",
  lato_bold: "lato,Bold",
);
