@font-face {
  font-family: "lato";
  src: url("../assets/fonts/Lato/Lato-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "lato-bold";
  src: url("../assets/fonts/Lato/Lato-Bold.woff2") format("woff2");
}

@import "~@angular/material/theming";

$custom-typography: mat-typography-config(
  $font-family: "lato, regular",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);
@include angular-material-typography($custom-typography);
