.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #63b570;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: #63b57066;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #d2d1d2;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #d2d1d2;
}
