@import "./variables";

.mat-flat-button.mat-flat-button {
  padding: 0 25px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;

  margin-right: 10px;
}

.mat-flat-button.mat-vg-primary {
  background-color: map-get($vg-green, 500);
  color: #ffffff;
}

.mat-flat-button.mat-vg-accent {
  background-color: map-get($vg-gray, 500);
  color: #ffffff;
}

.mat-button-toggle {
  font-family: "lato";
}

button {
  font-family: map-get($vg-font-family, lato) !important;
}

button:focus {
  outline: none;
  border: 0;
}

// Removing the focus overlay since it seems to be always active
// for some reason.
.mat-button-focus-overlay {
  display: none;
}
